
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Restaurant Menu Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      
      tableData: [],

      title: "Restaurant Menu ",
      items: [
        {
          text: "View",
        },
        {
          text: "Restaurant menu",
          active: true,
        },
      ],
      restaurants:[],
      branches:[],
      cityArr:[],
      city:"",
      // restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      // branchID:(sessionStorage.getItem('branchID')>0) ? [{'restBranchID':sessionStorage.getItem('branchID'),'branchName':sessionStorage.getItem('branchName')}] : "",
      restaurantID:"",
      restBranchID:"",
      branchID:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          
          sortable: false,
     
        },

        {
          key: "title",
          
          sortable: false,
        
        },
        {
          key: "categoryName",
          sortable: true,
        },
        {
          key: "price",
          sortable: true,
          tdClass: "align-right",
        },
        // {
        //   key: "minOrderQnty",
        //   sortable: true,
        // },
        {
          key: "foodType",
          sortable: true,
        },
        {
          key: "mealName",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
       
        
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.readApprovalRestaurantMenu();
    this.getRestaurantList();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readApprovalRestaurantMenu(){
     
         this.axios
        .post(this.$loggedRole+"/viewRestaurantMenuForApproval", {restID:this.restaurantID.restID,
          branchID: this.restBranchID,empTypeID:this.$storageData.profile.empTypeID,approverLvl: this.$storageData.profile.approverLvl ? this.$storageData.profile.approverLvl : 0}//this.branchID.restBranchID}
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           //this.readApprovalRestaurantMenu();
           //console.log((response));
          
        });
    },

    approveRestaurant(dishID){
            this.confirmMsg = "Are you sure you want to approve this Food Menu?";
        if((this.$storageData.profile.empTypeID==15) || (this.$storageData.profile.empTypeID==1 && this.$storageData.profile.approverLvl==1)){
            this.confirmMsg = "Are you sure you want to make this Food Menu live?";
        }
        if(confirm(this.confirmMsg)){
           this.axios
            .post(
            "approveRestaurantMenu", {approverLvl: this.$storageData.profile.approverLvl ? this.$storageData.profile.approverLvl : 0,empTypeID:this.$storageData.profile.empTypeID,dishID:dishID}
            )
            .then(() => {
                this.readApprovalRestaurantMenu();
            
            });
        }
    },
    getRestaurantCities(){
    
         this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {restID:(this.$storageData.login_type==1) ? this.restaurantID.restID : this.$storageData.profile.restID}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                 this.readRestaurantBranchData();
                
        });
    },

    getRestaurantList(){
         this.axios.post(this.$loggedRole+"/getRestaurantList"  ,
         {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empTypeID':this.$storageData.profile.empTypeID})
          .then((result)=>{
            this.restaurants = result.data.data;
            this.restaurantID = (this.restaurants) ? this.restaurants[0] :"";
            this.getRestaurantCities();
            //this.restBranchID = (this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11) ? 0 : this.$storageData.profile.restBranchID;
           
              
        });
    },
    onChangeRestBrand(){
  
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      //  if(this.restaurantID.restID>0){ /// for maintaining state of dropdown 
      //    sessionStorage.setItem('restID',this.restaurantID.restID);
      //    sessionStorage.setItem('restName',this.restaurantID.name);
      // }
      // if(this.branchID.restBranchID>0){ /// for maintaining state of dropdown 
      //    sessionStorage.setItem('branchID',this.branchID.restBranchID);
      //    sessionStorage.setItem('branchName',this.branchID.branchName);
      // }
    },
    onchangeBranch(){
      this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
      this.readApprovalRestaurantMenu();
    },

    
    readRestaurantBranchData(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {restID:this.restaurantID.restID,
          restBranchID:(this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11) ? 0 : this.$storageData.profile.restBranchID,
          'empTypeID':this.$storageData.profile.empTypeID,city: (this.city) ? this.city.city : ""}
        )
        .then((response) => {
         this.branches = response.data.data;
         this.branchID = (this.branches) ? this.branches[0] :"";
         this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          this.readApprovalRestaurantMenu();
          
        });
    },
    // applyFilter(){
    //   this.readApprovalRestaurantMenu();
    // }

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

  <div class="row">
    
    <div class="col-md-12">
     
            <div class=" mb-3">
                <div class="row">
                  <div class="col-md-4">
                      <label>Select Restaurant Brand </label>
                        <multiselect v-model="restaurantID" :options="restaurants" track-by="restID" label="name"  @input="onChangeRestBrand();"
                        ></multiselect>
                  </div>
                     <!-- Cities List-->
                    <div class="col-md-2" 
                    >   

                    <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
                    </div>
                    <!--END Cities List-->
                  <div class="col-md-4">
                     <label>Select Restaurant Branch </label>
                        <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName" @input="onchangeBranch()"
                          ></multiselect>
                  </div>
                   <!-- <div class="col-md-4">
                  <button type="button" class="btn btn-themeOrange w-md waves-effect waves-light" @click="applyFilter();" style="margin-top: 27px;">Apply filter</button>
                  </div> -->
                </div>
            </div>
   
    </div>
</div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown" v-if="this.$storageData.login_type == 1">Excel</button>
                       <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
                 fixed-header
              >
              
               <template v-slot:cell(Action)="data">
               
                   <keep-alive>
                   <router-link :to="{ name: 'restaurantMenuDetails', params: { id: data.item.dishID } }">
                     <i class="uil-info-circle" title="Info" style="font-size: 19px;"></i></router-link>
                     </keep-alive>
                  <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
                  <button v-if="data.item.isActive==3" class="btn btn-themeYellow" style="padding: 0px 7px;" @click="approveRestaurant(data.item.dishID)"> Approve </button>
                  <button v-else class="btn btn-themeYellow" style="padding: 0px 7px;" @click="approveRestaurant(data.item.dishID)"> Make Live </button>
                </template>
                <template v-slot:cell(price)="data">
                 <span v-if="data.item.price">₹{{data.item.price}}</span>
                </template>
                
                <template slot="image" slot-scope="data">
                   <img  v-bind:src="data.item.name" />  
                </template>

              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

